export const isBrowser = () => typeof window !== "undefined"

const credentials = [
  {
    passphrase: process.env.GATSBY_PASSPHRASE,
    ticket_type: "all-access",
    name: "All-Access Pass",
    secret: process.env.GATSBY_SECRET,
  },
  {
    passphrase: "fieldouterpaper",
    ticket_type: "all - access",
    name: "Partner | ARENA2036",
    secret: "bc08eb37a7fa428eb0cd7b38dc9277d3",
  },
  {
    passphrase: "centswherefound",
    ticket_type: "all-access",
    name: "Partner – University of Stuttgart",
    secret: "8fe5a4399d0f495c917db3fe9ad163f5",
  },
  {
    passphrase: "begananglefavor",
    ticket_type: "all-access",
    name: "Partner – BASF",
    secret: "81da4fa76304445a9ee4fb4c11af99f6",
  },
  {
    passphrase: "beginsmallapple",
    ticket_type: "all-access",
    name: "Partner – BOSCH",
    secret: "e8b3d2bcef8c4614820a2fa4757cb58b",
  },
  {
    passphrase: "guessafterwaves",
    ticket_type: "all-access",
    name: "Partner – Daimler",
    secret: "326cb4705b224d0cb6add6d00513aa77",
  },
  {
    passphrase: "wouldreplyplace",
    ticket_type: "all-access",
    name: "Partner – DPDHL",
    secret: "87897c5fbba14189b764e94d2ba98f46",
  },
  {
    passphrase: "greenallowthink",
    ticket_type: "all-access",
    name: "Partner – DXC",
    secret: "5611ffb25bec4e219e5ee5abe8d8c376",
  },
  {
    passphrase: "enterearthshirt",
    ticket_type: "all-access",
    name: "Partner – Motherson",
    secret: "13ac22b681244ab4abd414d809010ad9",
  },
  {
    passphrase: "orderanglebreak",
    ticket_type: "all-access",
    name: "Partner – Porsche",
    secret: "174f961257724633b679a11102609dc7",
  },
  {
    passphrase: "plantgreatcould",
    ticket_type: "all-access",
    name: "Partner – Wieland",
    secret: "24130d62f99c4621bfa5f75922ba3273",
  },
  {
    passphrase: "teachclosefifth",
    ticket_type: "all-access",
    name: "Partner – RRPS",
    secret: "190ac0a65d1844d7ade631deff7c434e",
  },
  {
    passphrase: "servecoastdaily",
    ticket_type: "all-access",
    name: "Partner – ZF",
    secret: "d0e1dd65eb724210a2655ef342297b88",
  },
  {
    passphrase: "crowdstillround",
    ticket_type: "all-access",
    name: "Partner – AGC",
    secret: "48274a440647462c85df2a4c077bdd75",
  },
  {
    passphrase: "happycolorstood",
    ticket_type: "all-access",
    name: "Partner – ADAC",
    secret: "8fa7e1e72c974c59bd53c1917aca11cd",
  },
  {
    passphrase: "wouldfortymight",
    ticket_type: "all-access",
    name: "Partner – T-systems",
    secret: "c3833bfbcb6f421fa96cb52eb6326621",
  },
  {
    passphrase: "theirmusiccheck",
    ticket_type: "all-access",
    name: "Partner – BP",
    secret: "9e51551b662f4c208790f0b4b5189743",
  },
  {
    passphrase: "watermajorusual",
    ticket_type: "all-access",
    name: "Partner – CoVea",
    secret: "79ddcf8122e14a08a29ebb58096ccf84",
  },
  {
    passphrase: "shirtenterlight",
    ticket_type: "all-access",
    name: "Partner – Eberspächer",
    secret: "9c6952a92a3f40a9a3406d778ea7b3df",
  },
  {
    passphrase: "righttotaltable",
    ticket_type: "all-access",
    name: "Partner – Faurecia",
    secret: "6519d880937c4f1eae1dcdd85ba6adb1",
  },
  {
    passphrase: "everydreamtries",
    ticket_type: "all-access",
    name: "Partner – Hyundai",
    secret: "687364ef3adc440eb2e3da69b5843307",
  },
  {
    passphrase: "sincebeingsharp",
    ticket_type: "all-access",
    name: "Partner – Jardines",
    secret: "eba50d59b85f48de9788b83ba440c1d5",
  },
  {
    passphrase: "whosewhichshade",
    ticket_type: "all-access",
    name: "Partner – Linde",
    secret: "994ba3eafd964a3591b326c6b0d497ac",
  },
  {
    passphrase: "clearearthouter",
    ticket_type: "all-access",
    name: "Partner – Murata",
    secret: "7d363ebc6f314a02b7e4a6fcda6ed2e2",
  },
  {
    passphrase: "stormbringguess",
    ticket_type: "all-access",
    name: "Partner – Sekisui",
    secret: "242fcdb9c82b49f58f32315d574c4b12",
  },
  {
    passphrase: "cloudradioocean",
    ticket_type: "all-access",
    name: "Partner – Webasto",
    secret: "0245fe01952a4d4ea7e9553597618e52",
  },
  {
    passphrase: "touchoffersight",
    ticket_type: "all-access",
    name: "Partner – TÜV Rheinland",
    secret: "aaabdd32c30b4843aa430b5032be8b6d",
  },
  {
    passphrase: "valuestorewater",
    ticket_type: "all-access",
    name: "Partner – Team",
    secret: "a0db6304b1d04949854f94f374efa866",
  },
  {
    passphrase: "wheatwrotecross",
    ticket_type: "all-access",
    name: "Startup Guests – Wiferion (Guest)",
    secret: "10d14fbcb2d642f89306b60297464e2a",
  },
  {
    passphrase: "lightplaneheart",
    ticket_type: "all-access",
    name: "Startup Guests – Riddle & Code (Guest)",
    secret: "36b8c6dc76eb4b94a6592aa60e2352a5",
  },
  {
    passphrase: "aprilsightworth",
    ticket_type: "all-access",
    name: "Startup Guests – 51Nodes (Guest)",
    secret: "470cb2d376d64d7ba6a2beb9a6a924d9",
  },
  {
    passphrase: "watchcheckangle",
    ticket_type: "all-access",
    name: "Startup Guests – ONTology (Guest)",
    secret: "5badfdb1cac84df0b0efae959b4b710f",
  },
  {
    passphrase: "enjoypiecebuilt",
    ticket_type: "all-access",
    name: "Startup Guests – Circularise (Guest)",
    secret: "8ea83781e0894cc094bfed91f723ef53",
  },
  {
    passphrase: "marchpaintshade",
    ticket_type: "all-access",
    name: "Startup Guests – Hi Auto (Guest)",
    secret: "a9aadb47b4ec423ba83ab9fe48906388",
  },
  {
    passphrase: "monthtablefresh",
    ticket_type: "all-access",
    name: "Startup Guests – climacell (Guest)",
    secret: "e868245319994aea941bd646419f333f",
  },
  {
    passphrase: "guesssincegreen",
    ticket_type: "all-access",
    name: "Startup Guests – Monk (Guest)",
    secret: "6992f5e03e9a488098b8d18afae2f3b1",
  },
  {
    passphrase: "planegreenplant",
    ticket_type: "all-access",
    name: "Startup Guests – Breezometer (Guest)",
    secret: "34f65ce5075b4b549db16ff3f254bac3",
  },
  {
    passphrase: "fifthfieldsmall",
    ticket_type: "all-access",
    name: "Startup Guests – Oculavis (Guest)",
    secret: "30a3796040014f93b07899a6d33cbafb",
  },
  {
    passphrase: "reachpiecewheel",
    ticket_type: "all-access",
    name: "Startup Guests – Hydrogenious (Guest)",
    secret: "6273edd06ad2491eb4f64668fe25c0c5",
  },
  {
    passphrase: "checknightfound",
    ticket_type: "all-access",
    name: "Startup Guests – Zensors (Guest)",
    secret: "2b77552470d449f89517d92193677823",
  },
  {
    passphrase: "standwholedaily",
    ticket_type: "all-access",
    name: "Startup Guests – Foretellix (Guest)",
    secret: "e922a9fc51844a1285e49ab9381a8fd7",
  },
  {
    passphrase: "mayorscoregrown",
    ticket_type: "all-access",
    name: "Startup Guests – Wem.io (Guest)",
    secret: "409b007e8a964d729a4f69a4a5bacf5f",
  },
  {
    passphrase: "otherblockfence",
    ticket_type: "all-access",
    name: "Startup Guests – VISPA (Guest)",
    secret: "21f565a041224cf8b88df9ac3c68b2b2",
  },
  {
    passphrase: "oceanlightradio",
    ticket_type: "all-access",
    name: "Startup Guests – Senseforce (Guest)",
    secret: "d426b0b1d85c4d6cb58e0e96b11430f2",
  },
  {
    passphrase: "placetermsstudy",
    ticket_type: "all-access",
    name: "Startup Guests – voiXen (Guest)",
    secret: "ae1287ff78d6480a961ee9bc1175ef8c",
  },
  {
    passphrase: "tastetrackfifth",
    ticket_type: "all-access",
    name: "Startup Guests – Inspekto (Guest)",
    secret: "db0120e7bbfa435ca3fcdd49c033f66e",
  },
  {
    passphrase: "musictrieswheel",
    ticket_type: "all-access",
    name: "Startup Guests – CircularTree (Guest)",
    secret: "1385125a7ed347fba1cc87be08867b36",
  },
  {
    passphrase: "toolsangleclass",
    ticket_type: "all-access",
    name: "Startup Guests – UBQ (Guest)",
    secret: "35fd9fda1e924eab9a1748cd948c5640",
  },
  {
    passphrase: "walesamongguard",
    ticket_type: "all-access",
    name: "Startup Guests – Synthesia (Guest)",
    secret: "ff94b3aa5a5c436b996f66a8156e287b",
  },
  {
    passphrase: "laterfancyagain",
    ticket_type: "all-access",
    name: "Startup Guests – Bleenco (Guest)",
    secret: "f77726b233e44214a8d48f52ae47b0ef",
  },
  {
    passphrase: "tastecarryagain",
    ticket_type: "all-access",
    name: "Startup Guests – Forciot Oy (Guest)",
    secret: "621c50f182274f9493e30b59df9744b4",
  },
  {
    passphrase: "scoreordertaken",
    ticket_type: "all-access",
    name: "Startup Guests – Imagine Intelligent Materials (Guest)",
    secret: "028ff1256c1d4b0881f06fe71bfd35d8",
  },
  {
    passphrase: "statesevenstart",
    ticket_type: "all-access",
    name: "Startup Guests – Noiseless Acoustics (Guest)",
    secret: "f25bfc77d7f24c988f0a6c3051251509",
  },
  {
    passphrase: "agreegrassnoise",
    ticket_type: "all-access",
    name: "Startup Guests – Silexica (Guest)",
    secret: "73adcf63406e4f4bb304dfaa4e4c52fc",
  },
  {
    passphrase: "sightguideshout",
    ticket_type: "all-access",
    name: "Startup Guests – Nexmachina (Guest)",
    secret: "39aa8d1080c546a08758335bb4e16f6e",
  },
  {
    passphrase: "soundfiftymarch",
    ticket_type: "all-access",
    name: "Startup Guests – DatenBerg (Guest)",
    secret: "8dce51c4b332442dacf60405aeab47c7",
  },
  {
    passphrase: "anglecrowdlater",
    ticket_type: "all-access",
    name: "Startup Guests – ASCon Systems (Guest)",
    secret: "66ab559e2d8e431a85dedf8d0d836609",
  },
  {
    passphrase: "enjoythesegreat",
    ticket_type: "all-access",
    name: "Guests – External Guests",
    secret: "a6deed34d8174a7fa7eb76625c62222d",
  },
  {
    passphrase: "greenmarchsharp",
    ticket_type: "all-access",
    name: "Guests – Investors",
    secret: "2b00783ffbe440ceb57942436ffcb019",
  },
  {
    passphrase: "speednorththird",
    ticket_type: "all-access",
    name: "Guests – Friends",
    secret: "d8c91b23a6d341628d15f62b440839fa",
  },
  {
    passphrase: "checkshirtshake",
    ticket_type: "all-access",
    name: "Guests – Investors",
    secret: "ced1d7412340423089c4857907b51262",
  },
  {
    passphrase: "musicstudycolor",
    ticket_type: "all-access",
    name: "Guests – External Speaker",
    secret: "228b811def6b48bc87d24ad722625f33",
  },
  {
    passphrase: "wherestatelevel",
    ticket_type: "all-access",
    name: "Guests – Press / Media",
    secret: "ce8c01880d1d475ea4a36fca039466cf",
  },
  {
    passphrase: "whilespeedsharp",
    ticket_type: "all-access",
    name: "Guests – External Speaker Guests",
    secret: "78a856261cfd45ce855e783c5a2b5fe6",
  },
  {
    passphrase: "dailysouthamong",
    ticket_type: "all-access",
    name: "Partner Guests – DXC Guests",
    secret: "c7acec24f0e14832b4ec7fd4eea847f9",
  },
  {
    passphrase: "countrivernorth",
    ticket_type: "all-access",
    name: "Partner Guests – Plug and Play Global Team",
    secret: "dea9d0a145de423ab3c13f3fb3336218",
  },
  {
    passphrase: "latersincehours",
    ticket_type: "all-access",
    name: "Partner Guests – Plug and Play Global Partner",
    secret: "4e8e053f22304bb1810061a33182148a",
  },
  {
    passphrase: "earlyplanesince",
    ticket_type: "all-access",
    name: "Startup – Startups (Program 8)",
    secret: "35c47cb0af0744fbbb34c65444f686b7",
  },
  {
    passphrase: "equalfreshmaybe",
    ticket_type: "all-access",
    name: "Startup – Startups (Alumni)",
    secret: "00ebb9faedfa4a0196f1d8e651694c61",
  },
]

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("EXPO8user")
    ? JSON.parse(window.localStorage.getItem("EXPO8user"))
    : {} 

const setUser = user =>
  window.localStorage.setItem("EXPO8user", JSON.stringify(user))


export const handleLogin = ({ accesscode }) => {
  for(var i = 0; i < credentials.length; i += 1) {
    if(credentials[i]["passphrase"] === accesscode) {
        return setUser(credentials[i])
    }
  }
  return false
};


export const isLoggedIn = () => {
  let userSecret = getUser().secret;

  for (let key in credentials) {
    let obj = credentials[key]
    if (obj.secret === userSecret) {
      return !!obj.secret
    }
  }

};

export const logout = callback => {
  setUser({})
  callback()
};