import React from "react"
import { Global, css } from "@emotion/core"
import { navigate } from 'gatsby';
import { color } from "../styles/theme";
import navigation_data from '../../../data/navigation_data';
import NavContent from "../footer/navContent"
import { wrapper, pretitle, IconButton } from '../styles/elements';
import Close from '../assets/svg/close.svg';
// import Zap from '../assets/svg/zap.svg';
import Video from '../assets/svg/video.svg';
import Lock from "../assets/svg/lock.svg"
import LockOpen from "../assets/svg/lock_open.svg"
import Expo8 from '../assets/logos/expo8_pri_wht_rgb.svg';
import Link from '../../link';
import { isLoggedIn, logout } from "../../../services/auth"
// import {
//   Default,
// } from "../assets/svg/mobilenav"
// import { live, blink } from "./navigation"



const Data = ({data}) => {
    return (
        data.map((cluster, index) => (
            <div key={index} css={[wrapper, css`
                padding: 2rem 2rem 1.2rem;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    background-color: ${color.main_dark};
                    opacity: .08;
                }
            `]}>
                <p key={index} css={[pretitle, `
                    padding-bottom: 1rem;
                `]}>{cluster.title}</p>
                <NavContent data={cluster.items} />
            </div>
        ))
    )
}

const Registration = () => {
    return (
      <div
        css={css`
          padding: 2rem 2rem 2rem;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: ${color.main_dark};
            opacity: 0.08;
          }
        `}
      >
        <div
          css={css`
            max-width: 6em;
            height: auto;
            margin-bottom: 1em;
          `}
        >
          <Expo8 />
        </div>
        {/* <p
          css={css`
            color: ${color.main_dark};
            font-size: 0.85em;
            max-width: 25em;
          `}
        >
          To safe you spot and to don‘t miss out on any updates regarding EXPO 8
          click on the button below to get one of the tickets.
        </p>
        <Link
          to="/register"
          css={css`
            border: none;
            background: none;
            width: 100%;
            text-decoration: none;
            font-size: 0.8rem;
            font-weight: 500;
            position: relative;
            padding: 0.475rem 1rem;
            display: flex;
            align-items: center;
            color: ${color.main_dark};
            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              border-radius: 5px;
              background: ${color.cta_green};
              opacity: 1;
              z-index: -1;
            }
            svg {
              height: 0.9em;
              width: auto;
              margin-right: 0.5rem;
              fill: none;
              stroke: ${color.main_dark};
            }
          `}
        >
          <Zap />
          Register Now
        </Link> */}

        <p
          css={css`
            color: ${color.main_dark};
            font-size: 0.85em;
            max-width: 25em;
          `}
        >
          If you did not have the chance to join us live or if you would like to re-watch your favorite content head over to the <i>Rearview section</i>.
        </p>
        <Link
          to="/rearview"
          css={css`
            border: none;
            background: none;
            width: 100%;
            text-decoration: none;
            font-size: 0.8rem;
            font-weight: 500;
            position: relative;
            padding: 0.475rem 1rem;
            display: flex;
            align-items: center;
            color: ${color.main_dark};
            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              border-radius: 5px;
              background: ${color.cta_green};
              opacity: 1;
              z-index: -1;
            }
            svg {
              height: 0.9em;
              width: auto;
              margin-right: 0.5rem;
              fill: none;
              stroke: ${color.main_dark};
            }
          `}
        >
          <Video />
          Check out the EXPO 8 Rearview
        </Link>

        <div
          css={css`
            margin-top: 1em;
          `}
        >
          {isLoggedIn() ? (
            <IconButton
              url="/"
              onClick={event => {
                event.preventDefault()
                logout(() => navigate(`/all-access/login`))
              }}
              name="Log Out"
              icon={<LockOpen />}
              css={css`
                display: flex;
                align-items: center;
                color: ${color.main_dark};
                padding: 0.375rem 0.7rem;
                svg {
                  stroke: ${color.main_dark};
                }
                &:before {
                  background: none;
                  border: 1px solid ${color.main_dark};
                }
              `}
            />
          ) : (
            <IconButton
              url="/all-access/login"
              name="All-Access Area"
              icon={<Lock />}
              css={css`
                display: flex;
                align-items: center;
                color: ${color.main_dark};
                padding: 0.375rem 0.7rem;
                svg {
                  stroke: ${color.main_dark};
                }
                &:before {
                  background: none;
                  border: 1px solid ${color.main_dark};
                }
              `}
            />
          )}
          <p
            css={css`
              padding: 0.5em 1em;
              opacity: 0.4;
              color: ${color.main_dark};
              font-size: 0.75em;
              font-weight: 500;
              a {
                color: ${color.main_dark};
              }
            `}
          >
            For more information about the All-Access-Pass please take a look at
            the <Link to="/register">Registration Section</Link>.
          </p>
        </div>
      </div>
    )
}

const Drawer = ({data, drawerActive, menuDrawer, drawerHeight, toggleDrawer}) => {
    return (
      <>
        {drawerActive ? (
          <Global
            styles={css`
              html,
              body {
                overflow: hidden;
              }
            `}
          />
        ) : (
          ""
        )}
        <section
          className={`drawer ${drawerActive}`}
          css={css`
            background-color: white;
            overflow: hidden;
            transition: height 0.6s ease;
            position: fixed;
            bottom: 4rem;
            width: 100%;
            z-index: 9900;
            max-height: calc(100% - 7rem);
            height: ${drawerHeight};
          `}
        >
          <div
            css={css`
              width: 100%;
              position: relative;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding: 0.8rem;
              &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: ${color.main_dark};
                opacity: 0.08;
              }
              svg {
                &:hover {
                  cursor: pointer;
                }
              }
            `}
          >
            <button
              onClick={toggleDrawer}
              css={css`
                background: none;
                border: none;
                padding: 0;
                line-height: 1em;
                outline: none;
              `}
            >
              <Close />
            </button>
          </div>
          <nav
            ref={menuDrawer}
            css={css`
              overflow: scroll;
              height: calc(100% - 2.5rem);
            `}
          >
            <Registration />
            <Data data={data} />
          </nav>
        </section>
      </>
    )
}

const MobileDrawer = ({ drawerActive, menuDrawer, drawerHeight, toggleDrawer }) => {
    return (
        <>
            <div onClick={toggleDrawer} onKeyDown={toggleDrawer} role="button" aria-label="Close Menu Drawer" tabIndex="0" css={css`
                background: black;
                opacity: .7;
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                display: ${ drawerActive ? `block` : `none`};
                z-index: 99;
                &:hover {
                    cursor: pointer;
                }
            `}></div>
            <Drawer data={navigation_data.footer} drawerActive={drawerActive} menuDrawer={menuDrawer} drawerHeight={drawerHeight} toggleDrawer={toggleDrawer}/>
        </>
    )
}

export default MobileDrawer;