import React, { useState, useLayoutEffect } from "react"
import styled from "@emotion/styled";
import { css } from "@emotion/core"
import { color } from "../styles/theme";
import mq from "../styles/mediaquerys";
import Closeicon from "../assets/svg/close.svg"
import { bannerActive, setBannerStorage } from '../../../services/banner';
import Link from "../../link"
// import Link from '../../link';

const NotificationEl = styled.section`
    width: 100%;
    background: ${color.cta_green};
    /* background: ${color.main_light}; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${color.accent_light};
    height: 2.5rem;
    p,a {
        font-size: .8rem;
        color: ${color.main_dark};
        /* color: white; */
        margin: 0;
        font-weight: 600;
        letter-spacing: .04em;
        text-decoration: none;
    }
    span { /* was a before*/
        text-decoration: none;
        &:before {
            content: "";
            border-left: 1px solid black;
            height: 16px;
            line-height: 1;
            margin-left: 20px;
            margin-right: 20px;
            vertical-align: middle;
            opacity: .1;
            display: none;
            ${mq[2]} {
                display: inline-block;
            }
        }
    }
    .mobile {
        display: inline-block;
        ${mq[2]} {
            display: none;
        }
    }
    .nomobile {
        display: none;
        ${mq[2]} {
            display: inline-block;
        }
    }

`

export const CloseButton = ({className, action}) => {
    return(
        <button onClick={action} className={className} css={css`
            background: none;
            border: none;
            display: inline-flex;
            color: ${color.main_dark};
            position: absolute;
            right: 20px;
            cursor: pointer;
            opacity: .2;
        `}>
            <Closeicon css={css`
                height: 1.2rem;
                width: 1.2rem;
                fill: ${color.main_dark};
            `} />
        </button>
    )
}

const SABar = () => {
    const [bannerState, setBannerState] = useState(true)

    const handleBannerState = () => {
        setBannerState(bannerState => !bannerState )
        setBannerStorage(false)
    }

    useLayoutEffect(() => {
        const updateBannerState = () => {
            if (bannerActive() === false) {
                setBannerState(bannerState => !bannerState)
            }
        }
        updateBannerState()
    }, [])

    if( bannerState === true) {
        return (
          <NotificationEl>
            <Link to="/rearview" className="Link">
              <p className="slogan nomobile">EXPO8 Rearview now online</p>
              <span className="nomobile">
                an event by STARTUP AUTOBAHN powered by Plug and Play
              </span>
              {/* <span className="mobile">More about STARTUP AUTOBAHN</span>{" "} */}
              <span className="mobile">EXPO8 Rearview now online</span>{" "}
            </Link>
            {/* <Link to="/agenda/day2">
              We changed the agenda for Day 2
              <span className="nomobile">
                Make sure not to miss out on anything
              </span>
            </Link> */}
            <CloseButton action={handleBannerState} />
          </NotificationEl>
        )
    } else {
        return <></>
    }
}

export default SABar;