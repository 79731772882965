import React from 'react';
import { css } from "@emotion/core"
import { color } from "./defaults/styles/theme"
import { wrapper, TitleHeader, IconButton, ArrowLink } from "./defaults/styles/elements"
import mq from "./defaults/styles/mediaquerys"
// import Link from "./link"
import Message from '../components/defaults/assets/svg/message.svg';
import ExtLink from '../components/defaults/assets/svg/externallink.svg';
import { pressrelease_data } from '../components/main/pressrelease';


const PressElement = ({theme}) => {
  return (
    <div
      css={css`
        background: ${theme === color.accent_light ? color.main_light : color.accent_light};
        padding: 2rem;
        border-radius: 5px;
        max-width: 45rem;
        margin: 0 auto;
        z-index: 1;
        position: relative;
      `}
    >
      <TitleHeader
        title="Official EXPO 8 Press Release"
        icon={<Message />}
        css={css`
          padding: 0;
          h2 {
            font-size: 1.2em;
          }
          svg {
            stroke: white;
          }
        `}
      />
      <p
        css={css`
          font-size: 0.85rem;
          margin-bottom: 0.5em;
        `}
      >
        Interested in what happened on September 23rd & 24th, 2020? You would
        like to share the experience with friends, co-workers or even a bigger
        audience?
      </p>
      <p
        css={css`
          font-size: 0.85rem;
        `}
      >
        Our Press Release got you covered – whether you prefer a german or an english version.
      </p>
      {pressrelease_data.map((el, index) => (
        <div
          key={index}
          css={css`
            &:not(:last-of-type) {
              margin-bottom: 0.75em;
            }
          `}
        >
          <ButtonEl
            name={el.title}
            key={index}
            url={el.link}
            download_url={el.download}
            language={el.language}
          />
        </div>
      ))}
    </div>
  )
}

const ButtonEl = ({name, download_url, url, language}) => {
  return (
    <div css={css`
      display: flex;
      align-items: center;
    `}>
      <IconButton name={`${name} ${language === "english" ? "(en)" : language === "deutsch" ? "(de)" : ""}`} icon={<ExtLink />} url={url}  />
      <ArrowLink text={`or Download`} url={download_url} css={css`
        display: none;
        ${mq[0]} {
          display: inline-block;
        }
        margin: 0;
        font-size: .75em;
        letter-spacing: .05em;
        line-height: 1em;
        opacity: .3;
        color: ${color.main_dark};
        margin-left: 1em;
        svg {
          stroke: ${color.main_dark};
        }
      `} />
    </div>
  )
}

const PressFooter = ({...props}) => {
  return (
    <section
      css={[
        css`
          background: ${props.theme};
          position: relative;
          &:after {
            content: "";
            background: white;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 25%;
            width: 100%;
            z-index: 0;
          }
          &:before {
            content: "";
            background: ${color.main_dark};
            position: absolute;
            bottom: 25%;
            left: 0;
            height: 1px;
            width: 100%;
            opacity: 0.08;
            z-index: 0;
          }
        `,
      ]}
    >
      <div css={[wrapper]}>
        <PressElement theme={props.theme} />
      </div>
    </section>
  )
}

export default PressFooter;