import React from "react"
import Link from "../../link";
import { css } from "@emotion/core"
import { Linkedin, Twitter, Youtube, Instagram } from "../assets/svg/social";
import { color } from "../styles/theme";
import { pretitle, small, wrapper } from "../styles/elements";
import mq from "../styles/mediaquerys";
import MobileFooterNav from "./mobilefooternav"
import SALogo from "../assets/logos/startupautobahn.svg"
import navigation_data from "../../../data/navigation_data"

// CONTENT


// CSS

const footerBottom = css`
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    text-align: center;
    ${mq[1]} {
        flex-direction: row;
        justify-content: space-between;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: ${color.main_dark};
        opacity: .08;
    }

`

const footerTop = css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 5rem;
    padding: 5rem 2rem 0;
    ${mq[1]} {
        padding: 5rem 1rem 0;
    }
    ${mq[2]} {
        flex-direction: row;
    }
`


// ---

const StartupAutobahn = () => {
    return (
        <div css={[small, css`
            flex: 1 0 auto;
            opacity: 1;
            text-align: center;
            margin-bottom: 2rem;
            ${mq[1]} {
                text-align: left;
                padding-right: 5rem;
            }
            ${mq[2]} {
                margin-bottom: 0;
            }
            h2 {
                font-size: .9rem;
                margin-bottom: .357rem;
                line-height: 1.25em;
            }
            p, a {
                text-decoration: none;
                color: black;
            }
            p {
                margin: 0;
            }
        `]}>
            <div css={css`
                max-width: 8rem;
                margin: 0 auto 1.5rem;
                ${mq[1]} {
                    max-width: 9rem;
                    margin: 0 0 1.25rem;
                }
            `}><SALogo /></div>
            <h2>STARTUP AUTOBAHN<br/>powered by Plug and Play</h2>
            <p>Pfaffenwaldring 19</p>
            <p>70569 Stuttgart</p>
            <p>Germany</p>
            <a href="mailto:marketing-ger@pnptc.com" css={css`
                margin-top: .35rem;
                display: inline-block;
            `}>marketing-ger@pnptc.com</a>
        </div>
    )
}

const Socialicons = () => {
    const items = [
        {
            name: 'Twitter',
            icon: Twitter,
            url: 'https://twitter.com/@startupautobahn'
        },
        {
            name: 'Linkedin',
            icon: Linkedin,
            url: 'https://linkedin.com/company/10781616'
        },
        {
            name: 'Instagram',
            icon: Instagram,
            url: 'https://www.instagram.com/startupautobahn/'
        },
        {
            name: 'Youtube',
            icon: Youtube,
            url: 'https://www.youtube.com/channel/UCRvI70vBDeU4vaU0pz_ylGg'
        },
    ];
    
    return(
        <div css={[wrapper, css`
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 2.5rem;
            padding-bottom: 1rem;
            ${mq[1]} {
                justify-content: flex-start;
            }
            ${mq[2]} {
                padding-top: 1rem;
            }
        `]}>
            { items.map(social => (
                <div 
                css={css`
                    display: inline-flex;
                    margin: 0 .5em;
                    &:first-of-type {
                        margin-left: 0;
                    }
                    svg {
                        fill: ${color.main_dark};
                        height: .8em;
                        width: .9em;
                    }
                `}  
                key={social.name + '_icon'}>
                    <a href={social.url}>{social.icon()}</a>
                </div>
            )) }
        </div>
    )
}


const Crafted = () => {
    return (
        <p css={small}>
            Crafted with{" "}
            <a
                href="https://bastiankroggel.com/?utm_source=sa_expo8_website&utm_medium=website&utm_campaign=expo8_promotion" target="_blank" rel="noreferrer"
                css={css`
            color: black;
            text-decoration: none;
          `}
            >
                &hearts;
        </a>{" "}
        in Stuttgart
        </p>
    )
}

const Copyright = () => {
    return <p css={small}>Copyright &copy; 2020 Plug & Play Germany GmbH</p>
}

const FooterNav = ({data}) => {

    return (
        <div css={css`
            display: none;
            max-width: 75%;
            flex: 1 0 auto;
            justify-content: flex-end;
            ${mq[2]} {
                display: flex;    
            }
        `}>
            {data.map((cluster, index) => (
                <div key={index} css={css`
                    /* width: 100%; */
                    flex: 1 0 auto;
                    /* padding: 0 3.5rem 1rem; */
                    &:last-of-type {
                        width: auto;
                        padding-right: 0;
                        flex: 0 1 auto;
                    }
                `}>
                    <p css={[pretitle ,css`
                        margin-bottom: .575rem;
                    `]}>{cluster.title}</p>
                    <ul css={css`
                        margin: 0;
                        list-style: none;
                        li {
                            margin: 0;
                            font-size: .8rem;
                            line-height: 1.2em;
                            opacity: .475;
                            padding-bottom: .8em;
                        }
                    `}>
                        {cluster.items.map((item, index) => (
                            <li key={index}><Link to={item.url} css={css`
                                text-decoration: none;
                                color: ${color.main_dark};
                            `}>{item.name}</Link></li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}


const Footer = () => {
    const footer_data = navigation_data.footer;
    return(
        <footer css={css`
            background: white;
        `}>
            <div css={[wrapper, footerTop]}>
                <StartupAutobahn />
                <FooterNav data={footer_data} />
                <MobileFooterNav data={footer_data} />
            </div>
            <Socialicons/>
            <div css={[wrapper, footerBottom]}>
                <Copyright />
                <Crafted />
            </div>
        </footer>
    )
}

export default Footer;