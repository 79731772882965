export const isBrowser = () => typeof window !== "undefined"

export const getBannerState = () =>
  isBrowser() && window.sessionStorage.getItem("saBanner")
    ? window.sessionStorage.getItem("saBanner")
    : ""

export const setBannerStorage = bannerState =>
  window.sessionStorage.setItem("saBanner", bannerState)

export const bannerActive = () => {
  const bannerState = getBannerState();
  if (!bannerState) {
    return true;
  }
  return false
};

