import React from "react";
import { css, Global } from '@emotion/core';
import mq from '../components/defaults/styles/mediaquerys';

import Footer from "../components/defaults/footer/footer"
import Navigation from "../components/defaults/header/navigation"
import MobileNav from '../components/defaults/header/mobile_navigation';
import "typeface-inter"
import SEO from "../components/SEO/seo"
import Analytics from "../components/analytics";




const Layout = ({ children, seo, ...props }) => {

    return (
      <>
        <Global
          styles={css`
            body {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: antialiased;
              font-smoothing: antialiased;
            }
          `}
        ></Global>
        <SEO {...seo} />
        <Navigation {...props} />
        <div>{children}</div>
        <Footer />
        <div
          css={css`
            height: 4rem;
            display: block;
            ${mq[1]} {
              display: none;
            }
          `}
        ></div>
        <MobileNav />
        <Analytics />
      </>
    )
}

export default Layout;