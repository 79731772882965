import React, { useState, useRef } from "react"
import { css } from "@emotion/core"
import { pretitle } from "../styles/elements";
import mq from "../styles/mediaquerys"
import { color } from "../styles/theme";
import NavContent from './navContent';


const defaults = css`

    /* Style the collapsable chevron icon */
    .collapsable__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
    }

    /* Style to rotate icon when state is active */
    .rotate {
    transform: rotate(90deg);
    }
`



function Collapsable(props) {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState('0px');
    const content = useRef(null);

    function toggleCollapsable() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
    }

    return (
        <div css={defaults} key={props.index}>
            <div css={css`
                display: flex;
                flex-direction: column;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    background: ${color.main_dark};
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    opacity: .08;
                }
            `}>
                <button className={`collapsable ${setActive}`}
                    onClick={toggleCollapsable}
                    css={css`
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            border: none;
                            outline: none;
                            background: none;
                            padding: .75rem 0;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        `}>
                    <p css={[pretitle, css`
                        margin: 0;
                    `]}>{props.title}</p>
                    <svg css={css`
                        stroke: ${color.main_dark};
                        height: 1em;
                        transform: ${setActive ? 'rotate(90deg)' : 'rotate(0)' };
                        transition: transform .4s ease-in-out;
                    `} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </button>
                <div className="collapsable__content"
                    ref={content}
                    css={css` 
                        max-height: ${setHeight};
                        background-color: white;
                        overflow: hidden;
                        transition: max-height .4s ease-in-out;
                     `}>
                    <NavContent data={props.content} />
                </div>
            </div>
        </div>
    );
}

const MobileFooterNav = ({data}) => {
    return(
        <nav css={css`
            display: flex;
            flex-direction: column;
            ${mq[2]} {
                display: none;
            }
        `}>
            {data.map((cluster, index) => (
                <Collapsable
                    title={cluster.title}
                    content={cluster.items}
                    key={index}
                />
            ))}
        </ nav>
    )
}

export default MobileFooterNav;