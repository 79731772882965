import React from 'react';
import { css } from "@emotion/core"
import { wrapper, IconButton } from "../defaults/styles/elements"
import mq from "../defaults/styles/mediaquerys"
import { color } from "../defaults/styles/theme"
import ExternalLink from "../defaults/assets/svg/externallink.svg"
import Download from "../defaults/assets/svg/download.svg"
import PressReleaseEN from "../../../static/media/PressRelease_en.pdf"
import PressReleaseDE from "../../../static/media/Pressemeldung_de.pdf"

export const pressrelease_data = [
  {
    title: "Press Release EXPO 8",
    date: "2020-09-23",
    language: "english",
    link: "/pressrelease/en",
    download: PressReleaseEN,
  },
  {
    title: "Pressemeldung EXPO 8",
    date: "2020-09-23",
    language: "deutsch",
    link: "/pressrelease/de",
    download: PressReleaseDE,
  },
]

const PressRelease = () => {
  return (
    <section
      id="pressrelease"
      css={css`
        background: ${color.main_light};
        padding-top: 8rem;
      `}
    >
      <div css={[wrapper, css``]}>
        <div
          css={css`
            position: relative;
            margin-bottom: 1em;
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              background: ${color.main_dark};
              width: 100%;
              height: 1px;
              opacity: 0.28;
            }
          `}
        >
          <h2
            css={css`
              background: ${color.main_light};
              z-index: 1;
              position: relative;
              display: inline;
              padding: 0 1rem 0 0;
              font-size: 0.85em;
              text-transform: uppercase;
              font-weight: 700;
              letter-spacing: 0.075em;
            `}
          >
            Press Section
          </h2>
        </div>

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          `}
        >
          {pressrelease_data.map((el, index) => (
            <div
              key={index}
              css={css`
                background: white;
                border: 1px solid ${color.accent_light};
                border-radius: 5px;
                padding: 2em;
                position: relative;
                z-index: 1;
                flex: 0 0 100%;
                margin-bottom: 0.5em;
                ${mq[1]} {
                  flex: 0 0 calc(50% - 0.5em);
                }
              `}
            >
              <h3
                css={css`
                  text-transform: uppercase;
                  font-size: 1em;
                  letter-spacing: 0.025em;
                  margin-bottom: 0;
                `}
              >
                {el.title}
              </h3>
              <p
                css={css`
                  font-size: 0.85em;
                `}
              >
                Language: {el.language}
              </p>
              <div
                css={css`
                  & > *:not(last-of-type) {
                    margin-right: 0.5em;
                  }
                `}
              >
                <IconButton
                  to={el.link}
                  name="Access"
                  icon={<ExternalLink />}
                  css={css`
                    color: ${color.main_dark};
                    svg {
                      stroke: ${color.main_dark};
                    }
                    &:before {
                      background: none;
                      border: 1px solid ${color.main_dark};
                    }
                  `}
                />
                <IconButton
                  to={el.download}
                  name="Download"
                  icon={<Download />}
                  css={css`
                    color: ${color.main_dark};
                    svg {
                      stroke: ${color.main_dark};
                    }
                    &:before {
                      background: none;
                      border: 1px solid ${color.main_dark};
                    }
                  `}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default PressRelease;