import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css, keyframes } from "@emotion/core"
import { Link, navigate } from "gatsby"
import { isLoggedIn, logout } from '../../../services/auth';
import navigation_data from "../../../data/navigation_data"
import Logo from "../assets/logos/expo8_pri_wht_rgb.svg"
import { wrapper, IconButton } from "../styles/elements"
import { color } from "../styles/theme"
import SABar from "./sa-bar"
import mq from '../styles/mediaquerys';
import {
  Home,
  People,
  Loader,
  Smile,
  Archive,
  Default,
} from "../assets/svg/mobilenav"
import Calendar from "../assets/svg/calendar.svg"
import Speaker from "../assets/svg/speaker.svg"
// import Zap from '../assets/svg/zap.svg';
import Video from "../assets/svg/video.svg"
import Lock from '../assets/svg/lock.svg';
import LockOpen from '../assets/svg/lock_open.svg';

/***********/
export const live = false
export const registration = false
/***********/

const MainNav = styled.nav`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 50px; */
  z-index: 9000;
  ${mq[1]} {
    position: sticky;
  }
  .nav_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .backdrop {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    ${mq[1]} {
      &.scroll {
        backdrop-filter: saturate(180%) blur(8px);
        background-color: ${props =>
          props.dark ? "rgba(29, 29, 31, 0.7)" : "rgba(255, 255, 255, 0.7)"};
        &:after {
          content: "";
          position: absolute;
          height: 1px;
          background-color: ${props =>
            props.dark
              ? "rgba(255, 255, 255, 0.24)"
              : "rgba(29, 29, 31, 0.08)"};
          top: 100%;
          display: block;
          width: 100%;
          left: 0;
        }
      }
    }
  }
  .content {
    z-index: 2;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & > * {
    display: block;
  }
  ul {
    margin: 0;
    text-align: center;
    /* li:last-of-type a:not([data-active]) {
      padding-right: 0;
    } */
    a:not(.special),
    button {
      color: ${props => (props.dark ? "white" : color.main_dark)};
      text-decoration: none;
      font-size: 0.8rem;
      font-weight: 500;
      position: relative;
      padding: 0.475rem 0.8rem;
      display: flex;
      align-items: center;
      border: none;
      background: none;
      ${mq[3]} {
        padding: 0.475rem 1rem;
      }
      &.live {
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.15em;
      }
      &.calltoaction {
        color: ${props =>
          live && props.dark
            ? color.main_dark
            : live
            ? color.main_light
            : props.dark
            ? "white"
            : color.main_dark};
      }
      svg {
        height: 0.9em;
        width: auto;
        margin-right: 0.5rem;
        fill: none;
        stroke: ${props => (props.dark ? "white" : color.main_dark)};
      }
      &.live > svg {
        fill: white;
        stroke: white;
        animation: ${props => blink} 2s ease-in-out infinite;
      }
      &.calltoaction > svg {
        stroke: ${props =>
          live && props.dark
            ? color.main_dark
            : live
            ? color.main_light
            : props.dark
            ? "white"
            : color.main_dark};
      }
      &:before {
        content: "";
        position: absolute;
        background: none;
        opacity: 0.1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 5px;
      }
      &.live:before {
        background: ${color.rec};
        opacity: 1;
        z-index: -1;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.calltoaction:before {
        background: ${props =>
          live && props.dark
            ? color.main_light
            : live
            ? color.main_dark
            : color.cta_green};
        opacity: 1;
        z-index: -1;
        border-top-right-radius: ${live ? "0" : "5px"};
        border-bottom-right-radius: ${live ? "0" : "5px"};
      }
      &[data-active]:before {
        background: ${props => (props.dark ? "white" : color.main_dark)};
      }
    }
  }
`

const callToAction_data = [
  // {
  //   name: "Register Now",
  //   icon: Zap,
  //   url: "/register",
  //   customClass: "calltoaction",
  //   styling: css`
  //   `,
  // },
  // {
  //   name: "Live",
  //   icon: Default,
  //   url: "/live/one",
  //   customClass: "live",
  //   styling: css`
  //   `,
  // },
  {
    name: "Rearview",
    icon: Video,
    url: "/rearview",
    customClass: "calltoaction",
    styling: css`
    `,
  },
]

const CallToAction = ({url, icon, name, styling, customClass, i}) => {
  let Icon = icon

  return (
    <li
      css={css`
        list-style: none;
        display: none;
        margin: 0;
        padding: ${`1.5em ${i === 0 ? ".1em" : (i + 1) === callToAction_data.length ? "0" : ".3em"} 1.5em ${(i + 1) === callToAction_data.length ? ".1em" : ".3em"}`};
        ${mq[2]} {
          display: inline-block;
        }
      `}
    >
      <Link
        className={customClass}
        to={url}
        css={[
          css`
            outline: none;
            &:hover {
              cursor: pointer;
            }
          `,
          styling,
        ]}
      >
        <Icon />
        {name}
      </Link>
    </li>
  )
}

export const blink = keyframes`
  from { 
    opacity: 1.0; 
  }
  50% { 
    opacity: 0.1; 
  }
  to { 
    opacity: 1.0; 
  }
`


const Components = {
  Home: Home,
  Speaker: Speaker,
  Agenda: Calendar,
  People: People,
  Startups: Loader,
  Partners: Smile,
  Archive: Archive,
}


const getProps = ({ isPartiallyCurrent, href, location }) => {
  return {
    ...(isPartiallyCurrent && href !== "/"
      ? {
          "data-active": true,
        }
        : (href === "/" && location.pathname === "/") ? 
        {
          "data-active": true,
        }
        : (href === "/people/all" && location.pathname.includes("/people/") && !(location.pathname.includes("speakers"))) ? 
        {
          "data-active": true,
        }
        : (href === "/agenda/day1" && location.pathname.includes("/agenda/")) ? 
        {
          "data-active": true,
        }
        : (href === "/startups" && location.pathname.includes("/all-access/startups")) ? 
        {
          "data-active": true,
        }
      : {}),
  }
}

const NavItems = ({item, index}) => {

  let Icon = Default
  if (Components[item.name] !== undefined) {
    Icon = Components[item.name]
  }

  
  return (
    <li
      css={[
        css`
          list-style: none;
          display: none;
          padding: 1.5rem 0.3rem;
          margin: 0;
          ${mq[2]} {
            display: ${index > 4 ? "none" : "inline-block"};
          }
          ${mq[3]} {
            display: inline-block;
          }
          &:last-of-type {
            padding-right: 0;
          }
        `
      ]}
    >
      <Link to={item.url} getProps={getProps}>
        <Icon />
        {item.name}
      </Link>
    </li>
  )
}

const Navigation = ({...props}) => {
  
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const top = window.scrollY < 10;
      if ( !top && !scroll )  {
        setScroll(true);
      } else if ( top && scroll ) {
        setScroll(false);
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [scroll])
    
    return (
      <>
        {/* <LogoSection {...props}/> */}
        <SABar {...props} />
        <MainNav {...props}>
          <div className="nav_wrapper">
            <div className={`backdrop ${scroll ? "scroll" : ""}`}></div>
            <div className="content" css={wrapper}>
              <div
                css={css`
                  padding: 1.5rem 0;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  flex: 0 1 100%;
                  ${mq[2]} {
                    justify-content: flex-start;
                  }
                  svg {
                    vertical-align: middle;
                  }
                `}
              >
                <Link
                  to="/"
                  css={css`
                    line-height: 1em;
                    svg {
                      height: 20px;
                      fill: ${props.dark ? "white" : color.main_dark};
                    }
                  `}
                >
                  <Logo />
                </Link>

                <div
                  css={css`
                    margin-left: 1em;
                  `}
                >
                  {isLoggedIn() ? (
                    <IconButton
                      url="/"
                      onClick={event => {
                        event.preventDefault()
                        logout(() => navigate(`/all-access/login`))
                      }}
                      name="Log Out"
                      icon={<LockOpen />}
                      css={css`
                        display: flex;
                        align-items: center;
                        color: ${props.dark ? "white" : color.main_dark};
                        padding: 0.375rem 0.7rem;
                        svg {
                          stroke: ${props.dark ? "white" : color.main_dark};
                        }
                        &:before {
                          background: none;
                          border: 1px solid
                            ${props.dark ? "white" : color.main_dark};
                        }
                      `}
                    />
                  ) : (
                    <IconButton
                      url="/all-access/login"
                      name="All-Access Area"
                      icon={<Lock />}
                      css={css`
                        display: flex;
                        align-items: center;
                        color: ${props.dark ? "white" : color.main_dark};
                        padding: 0.375rem 0.7rem;
                        svg {
                          stroke: ${props.dark ? "white" : color.main_dark};
                        }
                        &:before {
                          background: none;
                          border: 1px solid
                            ${props.dark ? "white" : color.main_dark};
                        }
                      `}
                    />
                  )}
                </div>
              </div>
              <ul
                css={[
                  css`
                    flex: 1 0 auto;
                  `,
                ]}
              >
                {navigation_data.main.map((item, index) => (
                  <NavItems item={item} key={index} index={index} />
                ))}

                {/* {registration ? (
                  <li
                    className="ctalink"
                    css={css`
                      
                    `}
                  >
                    <Link
                      className="calltoaction"
                      to="/register"
                      css={css`
                        outline: none;
                        &:hover {
                          cursor: pointer;
                        }
                      `}
                    >
                      <Zap />
                      Register Now
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {live ? (
                  <li
                    className="ctalink"
                    css={css`

                    `}
                  >
                    <Link
                      className="live"
                      to="/live/one"
                      css={css`
                        outline: none;
                        &:hover {
                          cursor: pointer;
                        }
                      `}
                    >
                      <Default />
                      Live
                    </Link>
                  </li>
                ) : (
                  ""
                )} */}
                {callToAction_data.map((el, index) => (
                  <CallToAction
                    name={el.name}
                    url={el.url}
                    icon={el.icon}
                    styling={el.styling}
                    customClass={el.customClass}
                    key={index}
                    i={index}
                  />
                ))}
              </ul>
            </div>
          </div>
        </MainNav>
      </>
    )
}

export default Navigation;
